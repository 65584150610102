
      export default {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"data-name":"Layer 1","xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 91.09 87.92"}, attrs),
              ...rest,
            },
            children.concat([_c('circle',{attrs:{"cx":"45.54","cy":"43.96","r":"38.08","fill":"#0bb892"}}),_c('path',{attrs:{"d":"M40.28 66.17a5.48 5.48 0 01-3.88-1.61l-14.71-14.7a5.49 5.49 0 017.76-7.76l10.83 10.82 21.35-21.36a5.49 5.49 0 017.77 7.76L44.16 64.56a5.5 5.5 0 01-3.88 1.61z","fill":"#fff"}})])
          )
        }
      }
    